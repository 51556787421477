// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-index-js": () => import("/opt/build/repo/src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-index-js": () => import("/opt/build/repo/src/pages/lessons/index.js" /* webpackChunkName: "component---src-pages-lessons-index-js" */),
  "component---src-pages-activities-manifesto-md": () => import("/opt/build/repo/src/pages/activities/manifesto.md" /* webpackChunkName: "component---src-pages-activities-manifesto-md" */),
  "component---src-pages-lesson-plans-announcements-00-intro-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/00-intro.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-00-intro-md" */),
  "component---src-pages-lesson-plans-announcements-01-lesson-1-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/01-lesson-1.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-01-lesson-1-md" */),
  "component---src-pages-lesson-plans-announcements-03-lesson-3-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/03-lesson-3.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-03-lesson-3-md" */),
  "component---src-pages-lesson-plans-announcements-05-lesson-5-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/05-lesson-5.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-05-lesson-5-md" */),
  "component---src-pages-lesson-plans-announcements-02-lesson-2-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/02-lesson-2.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-02-lesson-2-md" */),
  "component---src-pages-lesson-plans-announcements-05-c-invision-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/05c-invision.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-05-c-invision-md" */),
  "component---src-pages-lesson-plans-announcements-04-lesson-4-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/04-lesson-4.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-04-lesson-4-md" */),
  "component---src-pages-lesson-plans-announcements-05-b-sketch-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/05b-sketch.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-05-b-sketch-md" */),
  "component---src-pages-lesson-plans-announcements-06-lesson-6-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/06-lesson-6.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-06-lesson-6-md" */),
  "component---src-pages-lesson-plans-announcements-07-lesson-7-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/07-lesson-7.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-07-lesson-7-md" */),
  "component---src-pages-lesson-plans-announcements-08-lesson-8-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/08-lesson-8.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-08-lesson-8-md" */),
  "component---src-pages-lesson-plans-announcements-09-lesson-9-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/09-lesson-9.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-09-lesson-9-md" */),
  "component---src-pages-lesson-plans-announcements-10-lesson-10-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/10-lesson-10.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-10-lesson-10-md" */),
  "component---src-pages-lesson-plans-announcements-12-lesson-12-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/12-lesson-12.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-12-lesson-12-md" */),
  "component---src-pages-lesson-plans-announcements-11-lesson-11-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/11-lesson-11.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-11-lesson-11-md" */),
  "component---src-pages-lesson-plans-discussions-00-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/00-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-00-discussions-md" */),
  "component---src-pages-lesson-plans-announcements-midterms-md": () => import("/opt/build/repo/src/pages/lesson-plans/announcements/midterms.md" /* webpackChunkName: "component---src-pages-lesson-plans-announcements-midterms-md" */),
  "component---src-pages-lesson-plans-discussions-01-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/01-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-01-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-02-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/02-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-02-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-04-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/04-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-04-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-03-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/03-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-03-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-05-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/05-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-05-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-06-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/06-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-06-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-07-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/07-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-07-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-08-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/08-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-08-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-09-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/09-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-09-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-10-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/10-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-10-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-11-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/11-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-11-discussions-md" */),
  "component---src-pages-lesson-plans-discussions-12-discussions-md": () => import("/opt/build/repo/src/pages/lesson-plans/discussions/12-discussions.md" /* webpackChunkName: "component---src-pages-lesson-plans-discussions-12-discussions-md" */),
  "component---src-pages-lessons-01-contextual-research-md": () => import("/opt/build/repo/src/pages/lessons/01-contextual-research.md" /* webpackChunkName: "component---src-pages-lessons-01-contextual-research-md" */),
  "component---src-pages-lessons-00-introduction-md": () => import("/opt/build/repo/src/pages/lessons/00-introduction.md" /* webpackChunkName: "component---src-pages-lessons-00-introduction-md" */),
  "component---src-pages-lessons-02-conceptual-models-md": () => import("/opt/build/repo/src/pages/lessons/02-conceptual-models.md" /* webpackChunkName: "component---src-pages-lessons-02-conceptual-models-md" */),
  "component---src-pages-lessons-03-interpretation-gulfs-md": () => import("/opt/build/repo/src/pages/lessons/03-interpretation-gulfs.md" /* webpackChunkName: "component---src-pages-lessons-03-interpretation-gulfs-md" */),
  "component---src-pages-lessons-04-sharing-data-md": () => import("/opt/build/repo/src/pages/lessons/04-sharing-data.md" /* webpackChunkName: "component---src-pages-lessons-04-sharing-data-md" */),
  "component---src-pages-lessons-05-garretts-elements-md": () => import("/opt/build/repo/src/pages/lessons/05-garretts-elements.md" /* webpackChunkName: "component---src-pages-lessons-05-garretts-elements-md" */),
  "component---src-pages-lessons-06-strategy-scope-md": () => import("/opt/build/repo/src/pages/lessons/06-strategy-scope.md" /* webpackChunkName: "component---src-pages-lessons-06-strategy-scope-md" */),
  "component---src-pages-lessons-07-structure-md": () => import("/opt/build/repo/src/pages/lessons/07-structure.md" /* webpackChunkName: "component---src-pages-lessons-07-structure-md" */),
  "component---src-pages-lessons-08-skeleton-surface-md": () => import("/opt/build/repo/src/pages/lessons/08-skeleton-surface.md" /* webpackChunkName: "component---src-pages-lessons-08-skeleton-surface-md" */),
  "component---src-pages-lessons-09-emotional-design-md": () => import("/opt/build/repo/src/pages/lessons/09-emotional-design.md" /* webpackChunkName: "component---src-pages-lessons-09-emotional-design-md" */),
  "component---src-pages-lessons-10-personality-engagement-md": () => import("/opt/build/repo/src/pages/lessons/10-personality-engagement.md" /* webpackChunkName: "component---src-pages-lessons-10-personality-engagement-md" */),
  "component---src-pages-lessons-11-obstacles-forgiveness-md": () => import("/opt/build/repo/src/pages/lessons/11-obstacles-forgiveness.md" /* webpackChunkName: "component---src-pages-lessons-11-obstacles-forgiveness-md" */),
  "component---src-pages-lessons-12-risk-reward-md": () => import("/opt/build/repo/src/pages/lessons/12-risk-reward.md" /* webpackChunkName: "component---src-pages-lessons-12-risk-reward-md" */),
  "component---src-pages-resources-md": () => import("/opt/build/repo/src/pages/resources.md" /* webpackChunkName: "component---src-pages-resources-md" */),
  "component---src-pages-activities-project-2-md": () => import("/opt/build/repo/src/pages/activities/project-2.md" /* webpackChunkName: "component---src-pages-activities-project-2-md" */),
  "component---src-pages-activities-project-1-md": () => import("/opt/build/repo/src/pages/activities/project-1.md" /* webpackChunkName: "component---src-pages-activities-project-1-md" */),
  "component---src-pages-lesson-plans-index-md": () => import("/opt/build/repo/src/pages/lesson-plans/index.md" /* webpackChunkName: "component---src-pages-lesson-plans-index-md" */)
}

